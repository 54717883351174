import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ReplayIcon from "@material-ui/icons/Replay";
import ApiService from "./ApiService";
import TablePagination from '@material-ui/core/TablePagination';
import { Chip, Tooltip } from "@material-ui/core";

export default class TaskList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            tasks: [],
            numOfTasks: 0,
            page: 0,
            offset: 0,
            limit: 10
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleLimitChange = this.handleLimitChange.bind(this);
        this.refreshTasks = this.refreshTasks.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.resetTask = this.resetTask.bind(this);
    }

    handlePageChange(evt, page) {
        this.setState({page: page, offset: this.state.limit * page}, () => this.refreshTasks());
    }
    
    handleLimitChange(evt) {
        this.setState({limit: parseInt(evt.target.value, 10), page: 0, offset: 0}, () => this.refreshTasks());
    }

    async refreshTasks() {
        try {
            if (this.props.configId && this.props.open) {
                const data = await ApiService.getTaskList(this.props.configId, this.state.limit, this.state.offset);
                this.setState({
                    isLoaded: true,
                    tasks: data && data.tasks ? data.tasks : [],
                    numOfTasks: data && data.numberOfTasks ? data.numberOfTasks : 0
                });
            }
        } catch (err) {
            this.setState({
                isLoaded: true,
                error: err
            });
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.open && this.props.open) {
            await this.refreshTasks();
        }
    }

    formatDate(dateString) {
        return new Date(dateString).toLocaleString();
    }

    downloadFile(taskId) {
        ApiService.downloadTaskFile(taskId);
    }

    resetTask(taskId) {
        ApiService.resetTask(taskId);
    }

    render() {
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                        <Collapse in={this.props.open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                    History
                                </Typography>
                                <Table size="small" aria-label="history">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Created</TableCell>
                                            <TableCell>Sent</TableCell>
                                            <TableCell>Received</TableCell>
                                            <TableCell>Reseted</TableCell>
                                            <TableCell>State</TableCell>
                                            <TableCell># Items</TableCell>
                                            <TableCell># Files</TableCell>
                                            <TableCell>DryRun</TableCell>
                                            <TableCell align="center">Options</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.tasks && this.state.tasks.map((task) => (
                                            <TableRow key={task.id}>
                                                <TableCell component="th" scope="row">
                                                    {this.formatDate(task.createdAt)}
                                                </TableCell>
                                                <TableCell>{this.formatDate(task.sentAt)}</TableCell>
                                                <TableCell>{this.formatDate(task.receivedAt)}</TableCell>
                                                <TableCell>{this.formatDate(task.resetedAt)}</TableCell>
                                                <TableCell><Tooltip title={task.responseBody}><Chip variant="outlined" color={task.result === 'Succeeded' ? 'primary' : 'secondary'} size="small" label={task.result}/></Tooltip></TableCell>
                                                <TableCell>{task.itemCount}</TableCell>
                                                <TableCell>{task.fileCount}</TableCell>
                                                <TableCell>{task.dryRun ? 'true' : 'false'}</TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title={'Download file for task ' + task.id}>
                                                        <span>
                                                            <IconButton size="small" onClick={() => {
                                                                this.downloadFile(task.id)
                                                            }}>
                                                                <SaveAltIcon/>
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title={'Reset items to "available" for task ' + task.id}>
                                                        <span>
                                                            <IconButton size="small" disabled={task.result === 'Failed' || task.dryRun || task.resetedAt != null} onClick={() => {
                                                                this.resetTask(task.id)
                                                            }}>
                                                                <ReplayIcon/>
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={this.state.numOfTasks}
                                    page={this.state.page}
                                    onPageChange={this.handlePageChange}
                                    rowsPerPage={this.state.limit}
                                    onRowsPerPageChange={this.handleLimitChange}
                                />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>);
    }
}