import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";

export default class AutoCompleteWidget extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : null,
            options: this.props.schema.examples ? this.props.schema.examples : []
        }
    }

    filterOptions = () => {
        if(!this.state.value){
            return this.state.options;
        }
        let filteredOptions = [];
        this.state.options.forEach((element) => {
            if (element
                    .replace(",", "")
                    .toLowerCase()
                    .includes(this.state.value.toLowerCase())
            )
                filteredOptions.push(element);
        });
        return filteredOptions;
    };

    render() {
        return (
            <Autocomplete
                disablePortal
                id="field-options"
                options={this.state.options}
                getOptionLabel={option => option}
                filterOptions={this.filterOptions}
                value={this.state.value}
                onChange={(event, newValue) => {
                    this.setState((prevState)=>{
                        return{
                            ...prevState,
                            value: newValue,
                        }
                    })
                    this.props.onChange(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    this.setState((prevState)=>{
                        return{
                            ...prevState,
                            value: newInputValue
                        }
                    })
                    this.props.onChange(newInputValue);
                }}
                renderInput={(params) => <TextField {...params} label="Field"/>}
            />
        );
    }


}