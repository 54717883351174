import axios from "axios";

const API_ENDPOINT = window?.appConfig?.DELIVERY_API_ENDPOINT;
const TOKEN_NAME = "token=";

const http = axios.create({
    baseURL: `${API_ENDPOINT}/v1/`,
    headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
    },
    withCredentials: false
});

export const initAuthenticationToken = ()  => {
    const token = getToken();
    if (token) {
        http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    return token;
}

// return the token from the session storage
export const getToken = () => {
    try {
        return document.cookie
    .split('; ')
    .find(row => row.startsWith(TOKEN_NAME))
    .split('=')[1];
    } catch (err) {
        return null;
    }
}

// remove the token and user from the session storage
export const removeUserSession = () => {
    document.cookie = TOKEN_NAME + '; max-age=-1; path=/;';
    delete http.defaults.headers.common['Authorization'];
}

// set the token and user from the session storage
export const setUserSession = (token, expires_in) => {
    document.cookie = TOKEN_NAME + token + '; max-age=' + expires_in + '; path=/;'
    http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

export default http;