import React from "react";
import Button from "@material-ui/core/Button";
export default class ScrollButton extends React.Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            showScrollToTopButton: false
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.handleScrollToButtonClick = this.handleScrollToButtonClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('scroll',this.handleScroll);
    }

    handleScroll(){
        if(this._isMounted){
            window.pageYOffset > 0 ? this.setState((prevState)=>{
                return {
                    ...prevState,
                    showScrollToTopButton: true
                }
            }) : this.setState((prevState)=>{
                return {
                    ...prevState,
                    showScrollToTopButton: false
                }
            });
        }
    }

    handleScrollToButtonClick(){
        if(this._isMounted){
            this.state.showScrollToTopButton ? window.scrollTo(0,0) : window.scrollTo(0,document.body.scrollHeight);
        }
    }


    render() {
        return(
            <Button variant={"contained"} color={"primary"} onClick={this.handleScrollToButtonClick} style={{position: "fixed", right: "30px", bottom: "30px"}}>{this.state.showScrollToTopButton ? <>&#9650;</> : <>&#9660;</>}</Button>
        )
    }

}