import React from "react";
import ApiService from "./ApiService";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            error: null
        };
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
        this.handleKeyPress();
    }

    async handleLogin() {
        this.setState({
            loading: true
        });
        try {
            await ApiService.login(this.state.email, this.state.password);
            this.setState(()=>{
                return{
                    email: '',
                    password: '',
                    loading: false,
                    error: null
                }
            })
            this.props.onLogin(true);
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message ? err.message : 'Error occurred!'
            })
        }
    }

    handleKeyPress(){
        document.addEventListener("keypress", (e)=>{
            if(e.keyCode === 13 && this.state.email && this.state.password){
                this.handleLogin();
            }
        })
    }

    render() {
        return (
            <div>
                <div>
                    <TextField type="text" label="E-Mail" value={this.state.email} onChange={(evt) => {this.setState({email: evt.target.value})}} autoComplete="new-password" fullWidth variant="outlined" margin="dense"/>
                </div>
                <div style={{ marginTop: 10 }}>
                    <TextField type="password" label="Password" value={this.state.password} onChange={(evt) => {this.setState({password: evt.target.value})}} autoComplete="new-password" fullWidth variant="outlined" margin="dense"/>
                </div>
                {this.state.error && <><small style={{ color: 'red' }}>{this.state.error}</small><br /></>}<br />
                <Button type="button" variant="contained" color="primary" onClick={this.handleLogin} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Login'}</Button>
            </div>
        );
    }
}