import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';


// password with hide button

export default class PasswordWidget extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  render() {
    return (
    <FormControl>
      <InputLabel>Password</InputLabel>
      <Input
        type={this.state.showPassword ? 'text' : 'password'}
        value={this.props.value}
        onChange={(evt) => {this.props.onChange(evt.target.value)}}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={(evt) => {this.setState({showPassword: !this.state.showPassword})}}
              onMouseDown={(evt) => {evt.preventDefault();}}
            >
              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
      }
}