import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import ApiService from "./ApiService";
import TaskList from "./TaskList";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import RevisionList from './RevisionList';
import DescriptionIcon from '@material-ui/icons/Description';

export default class ConfigurationRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openTaskList: false,
            openConfirmationDialog: false,
            openRevisionDialog: false,
            openDeliveryDialog: false,
            deleteConfirmationText: "",
            confirmationDialogError: ""
        };
        this.handleEdit = this.handleEdit.bind(this);
        this.handleError = this.handleError.bind(this);
        this.deleteConfig = this.deleteConfig.bind(this);
        this.deliverConfig = this.deliverConfig.bind(this);
        this.dryRunConfig = this.dryRunConfig.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
        this.triggerRefresh = this.triggerRefresh.bind(this);
        this.checkDeleteConfig = this.checkDeleteConfig.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.closeConfirmationDialog = this.closeConfirmationDialog.bind(this);
    }

    handleEdit(configId) {
        if(this.props.onEdit) {
            this.props.onEdit(configId);
        }
    }

    handleError(err) {
        if (this.props.onError) {
            this.props.onError(err);
        }
    }

    triggerRefresh(){
        if(this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    async deliverConfig() {
        try {
            this.setState({openDeliveryDialog: false});
            await ApiService.deliverConfig(this.props.config.id);
    
            this.triggerRefresh();
        } catch(err) {
            this.handleError(err);
        }
    }

    async dryRunConfig() {
        try {
            await ApiService.deliverConfig(this.props.config.id, true);
            this.triggerRefresh();
        } catch(err) {
            this.handleError(err);
        }
    }

    async checkDeleteConfig() {
        if (this.state.deleteConfirmationText === this.props.config.name) {
            this.closeConfirmationDialog();
            await this.deleteConfig();
        } else {
            this.setState({confirmationDialogError: "The provided name doesn't match the config name"});
        }
    }

    async deleteConfig() {
        try {
            await ApiService.deleteConfig(this.props.config.id);
            this.triggerRefresh();
        } catch(err) {
            this.handleError(err);
        }
    }

    confirmDelete() {
        this.setState({
            openConfirmationDialog: true
        });
    }

    closeConfirmationDialog() {
        this.setState({
            openConfirmationDialog: false,
            deleteConfirmationText: "",
            confirmationDialogError: ""
        })
    }

    async toggleActive(configId, active) {
        try {
            await ApiService.toggleConfigActive(configId, active);
            this.triggerRefresh();
        } catch(err) {
            this.handleError(err);
        }
    }

    render() {
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({openTaskList: !this.state.openTaskList})}>
                            {this.state.openTaskList ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell><Tooltip title={'Id: ' + this.props.config.id}><div>{this.props.config.name}</div></Tooltip></TableCell>
                    <TableCell>
                        <Switch checked={this.props.config.active} onChange={(evt, value) => {this.toggleActive(this.props.config.id, value)}}/>
                    </TableCell>
                    <TableCell>{this.props.config.schedule}</TableCell>
                    <TableCell>{this.props.config.fileFormat}</TableCell>
                    <TableCell>{this.props.config.protocol}</TableCell>
                    <TableCell>{new Date(this.props.config.lastRun).toLocaleString()}</TableCell>
                    <TableCell>
                        <Tooltip title={'Trigger Delivery for ' + this.props.config.name}>
                            <span>
                                <IconButton size="small" color="secondary" disabled={!this.props.config.active} onClick={() => {this.setState({openDeliveryDialog: true})}}>
                                    <SendIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={'Trigger Dry-Run Delivery for ' + this.props.config.name}>
                            <span>
                                <IconButton size="small" disabled={!this.props.config.active} onClick={this.dryRunConfig}>
                                    <SendIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={'Edit ' + this.props.config.name}>
                            <IconButton size="small" onClick={() => {
                                this.handleEdit(this.props.config.id)
                            }}>
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Delete ' + this.props.config.name}>
                            <IconButton size="small" onClick={this.confirmDelete}><DeleteIcon/></IconButton>
                        </Tooltip>
                        <Tooltip title={'Revisions for ' + this.props.config.name}>
                            <IconButton size="small" onClick={() => {this.setState({openRevisionDialog: true})}}><DescriptionIcon/></IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
                <TaskList configId={this.props.config.id} open={this.state.openTaskList}/>
                <Dialog
                    open={this.state.openConfirmationDialog}
                    onClose={this.closeConfirmationDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete Configuration?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete "{this.props.config.name}"? Please retype the configuration name to approve:
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="config_name"
                            label="Configuration Name"
                            type="text"
                            fullWidth
                            error={this.state.confirmationDialogError !== ""}
                            helperText={this.state.confirmationDialogError}
                            onChange={(evt) => {this.setState({deleteConfirmationText: evt.target.value});}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmationDialog} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={this.checkDeleteConfig} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openRevisionDialog}
                    onClose={() => {this.setState({openRevisionDialog: false})}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Revisions"}</DialogTitle>
                    <DialogContent>
                        <RevisionList configId={this.props.config.id}></RevisionList>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openDeliveryDialog}
                    onClose={() => {this.setState({openDeliveryDialog: false})}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delivery"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                            Are you sure you want to deliver "{this.props.config.name}"?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({openDeliveryDialog: false})} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={this.deliverConfig} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
