import React from 'react';
import Button from "@material-ui/core/Button";
import {eventBus} from "./eventBus";
import ApiService from "./ApiService";
import {CircularProgress} from '@material-ui/core';
import {Alert} from "@material-ui/lab";

export default class CustomButtonWidget extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            numberOfDeliveredItems: null,
            numberOfDeliveries: null,
            sequenceNumberId: null,
            disabledButton: true,
            showSpinner: false
        }
        this.submitEditHandler = this.submitEditHandler.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.setListeners();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setListeners(){
        eventBus.on("numberEdited", (e) => {
            if(this._isMounted){
                this.setState({
                    numberOfDeliveredItems: e.numberOfDeliveredItemsSeq,
                    numberOfDeliveries: e.numberOfDeliveriesSeq,
                    sequenceNumberId: e.sequenceNumberId,
                    disabledButton: false
                })
            }
        })
        eventBus.on("disableButton", ()=>{
            if(this._isMounted){
                this.setState((prevState)=>{
                    return{
                        ...prevState,
                        disabledButton: true
                    }
                })
            }
        })
        eventBus.on("successfullyEdited",()=>{
            if(this._isMounted){
                this.setState((prevState)=>{
                    return{
                        ...prevState,
                        disabledButton: true,
                        showSpinner: false
                    }
                })
            }
        })
    }

    submitEditHandler() {
        if(this._isMounted){
            this.setState((prevState)=>{
                return{
                    ...prevState,
                    showSpinner: true
                }
            })
        }
        ApiService.editDeliveriesBySequenceNumber(this.state.sequenceNumberId, {
            numberOfDeliveredItems: this.state.numberOfDeliveredItems,
            numberOfDeliveries: this.state.numberOfDeliveries
        })
    }

    render() {
        return (
            !this.state.showSpinner ?
                <div>
                    <Button style={{width: '120px', marginBottom:'10px'}} variant="contained" color="primary" disabled={this.state.disabledButton}
                            onClick={this.submitEditHandler}>
                        Edit
                    </Button>
                    <Alert severity="warning">Be aware that this sequence number can also be used in other configurations! <br/> Edits a number of deliveries and delivered items </Alert>
                </div>
                : <CircularProgress size={40} style={{marginBottom:'10px'}}></CircularProgress>
        );
    }
}

