import React from 'react';
import ConfigurationForm from './ConfigurationForm';
import ConfigurationList from './ConfigurationList';
import Login from './Login';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {removeUserSession} from './http-common';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ScrollButton from "./ScrollButton";

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: props.loggedIn,
            editMode: false,
            configId: null,
            error: null,
            showScrollToTopButton: false,
        };
        this.editConfiguration = this.editConfiguration.bind(this);
        this.loggedIn = this.loggedIn.bind(this);
        this.showError = this.showError.bind(this);
    }

    editConfiguration(configId) {
        this.setState(
            {
                editMode: true,
                configId: configId
            }
        );
    }

    loggedIn(login) {
        this.setState({
            loggedIn: login
        });
    }

    showError(err) {
        this.setState({
            error: err.message
        });
    }

    render() {
       return  (
           <Container>
               <Grid container
                     direction="row"
                     justifyContent="space-between"
                     alignItems="center">
                   <h1>Polar Delivery</h1>
                   {this.state.loggedIn && <Button onClick={() => {this.loggedIn(false); removeUserSession();}} endIcon={<ExitToAppIcon/>}>Logout</Button>}
               </Grid>

               {this.state.loggedIn
                   ?
                   <div>
                       {this.state.editMode
                           ? <div>
                               <Grid container
                                     direction="row"
                                     justifyContent="space-between"
                                     alignItems="center">
                                   <h2>Edit Configuration</h2>
                                   <Button onClick={() => {
                                       this.setState({editMode: false})
                                   }} startIcon={<ArrowBackIcon/>}>Back</Button>
                               </Grid>
                               <ConfigurationForm configId={this.state.configId} onError={this.showError}/>
                           </div>
                           : <div>
                               <Grid container
                                     direction="row"
                                     justifyContent="space-between"
                                     alignItems="center">
                                   <h2>Configuration Overview</h2>
                                   <Button onClick={() => {
                                       this.setState({editMode: true, configId: null})
                                   }} startIcon={<AddIcon/>} variant="contained" color="primary">New</Button>
                               </Grid>
                               <ConfigurationList onEdit={this.editConfiguration} onError={this.showError}/>
                           </div>
                       }
                   </div>
                   : <Login onLogin={this.loggedIn}/>
               }
               <Snackbar open={this.state.error} autoHideDuration={6000} onClose={() => {this.setState({error: null})}}>
                   <Alert elevation={6} variant="filled" onClose={() => {this.setState({error: null})}} severity="error">
                       {this.state.error}
                   </Alert>
               </Snackbar>
                   <ScrollButton></ScrollButton>
           </Container>
       );
    }
}